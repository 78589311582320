import React, { Component } from 'react';
import { withFormik } from 'formik';
import Yup from 'yup';

import SignUp from '../../utils/SignUp';
import { validationPatterns } from '../../utils/validationPatterns';
import { SvgIco } from "../SharedComponents/Icons";

import './LoginForm.sass';


class LoginFormWrapper extends Component {
  state = {
    passwordVisibility: false,
  };

  togglePasswordVisibility = () => {
    this.setState({
      passwordVisibility: !this.state.passwordVisibility,
    });
  };

  errorValidator = (field) => {
    const { errors, touched } = this.props;
    const { errorsOnSubmit } = this.state;

    return errors[field] && (touched[field] || errorsOnSubmit) && 'login-form__form-group-error';
  };

  render() {

    const {
            values,
            handleChange,
            handleBlur,
            errors,
            handleSubmit,
            forgotPasswordLink,
            createAccountLink,
            loginError,
            setFieldValue,
          } = this.props;

    const { passwordVisibility } = this.state;


    return (
      <div className="login-form">
        <h2>{ I18n.t('views.sessions.login_box.login_headline') }</h2>

        { loginError &&
          <div className="login-form__login-error-message">
            { loginError }
          </div>
        }

        <form name="loginForm" className="login-form__form" onSubmit={ handleSubmit } noValidate>
          <input
            id="fifth_element"
            type="text"
            name="fifth_element"
            className="invisible"
            value={values.fifth_element}
            onPaste={handleChange}
            onChange={handleChange}
          />

          <div className={`form-group ${ this.errorValidator('email')}`}>
            <label className="login-form__label" htmlFor="email">
              <input
                name="email"
                type="email"
                value={values.email}
                id="email"
                onChange={handleChange}
                onBlur={handleBlur}
                data-tip={errors.email}
                className={`login-form__input ${values.email  && 'active'}`}
              />
              <span className={`login-form__floating-label ${values.email && 'active'}`}>
                { I18n.t('views.sessions.new.login.email') }*
              </span>
            </label>
            <div className="login-form__input-tooltip-error">
              { errors.email }
            </div>
          </div>

          <div className={`form-group ${ this.errorValidator('password')}`}>
            <label className="login-form__label" htmlFor="password">
              <input
                name="password"
                type={ passwordVisibility ? 'text' : 'password' }
                value={values.password}
                id="password"
                onChange={(e) => {
                  setFieldValue('password', e.target.value.replace(/\s/g, ""))
                }}
                onBlur={handleBlur}
                data-tip={ errors.password }
                className={`login-form__input ${values.password && 'active'}`}
              />
              <span className={`login-form__floating-label ${values.password && 'active'}`}>
                { I18n.t('generic.password') }*
              </span>
            </label>

            { passwordVisibility
              ? <SvgIco className="password-visibility" name="visibility_filled" onClick={this.togglePasswordVisibility} size={18} />
              : <SvgIco className="password-visibility" name="visibility_off_filled" onClick={this.togglePasswordVisibility} size={18} />
            }

            { !!this.errorValidator('password')
              ? <div className="login-form__input-tooltip-error">{ errors.password }</div>
              : <div className="login-form__input-tooltip">{ I18n.t('signup.new_form.password_tooltip') }</div>
            }
          </div>

          <div className="form-group form-group--checkbox">
            <div className="login-form__remember-me-label">
              <label htmlFor="remember_me">
                <input
                  name="remember_me"
                  type="checkbox"
                  value={false}
                  id="remember_me"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  data-tip={errors.remember_me}
                />
                <span className="checkmark" />
                <div dangerouslySetInnerHTML={{__html: I18n.t('views.sessions.new.login.remember_me_new')}} />
              </label>
            </div>
            <a href={forgotPasswordLink} className="login-form__forgot-password-link">
              { I18n.t('views.sessions.new.login.forgotten_password_new') }
            </a>
          </div>

          <div className="form-group form-group--submit">
            <button type="submit" className="b-button">
              { I18n.t('views.sessions.new.login.button') }
            </button>
          </div>
        </form>

        <a href={forgotPasswordLink} className="login-form__forgot-password-link login-form__forgot-password-link--mobile">
          { I18n.t('views.sessions.new.login.forgotten_password_new') }
        </a>

        <p className="login-form__sign-up-link">
          <span>{ I18n.t('views.sessions.login_box.sign_up_link_preline') }</span>
          <a href={createAccountLink}>
            { I18n.t('views.sessions.login_box.sign_up_link') }
          </a>
        </p>
      </div>
    )
  }
}

const { email, password, fifth_element } = SignUp.getClientInfo();
const { emailI18n, passwordI18n } = SignUp.getI18nAttrNames();

const i18nRequiredKey = 'apps.lb_showroom.detailed.order_form.validation.field_required';
const i18nMinNumberKey = 'apps.lb_showroom.detailed.order_form.validation.min_number';
const i18nInvalidEmailInput = 'apps.lb_showroom.detailed.order_form.validation.wrong_email';
const i18nSymbolsLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 254 });
const i18nSymbolsEmailLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 100 });

const LoginForm = withFormik({
  mapPropsToValues: () => { return { email, password, fifth_element }},

  validationSchema: Yup.object().shape({
    email: Yup.string()
              .matches(validationPatterns.symbolsEmailLimit, i18nSymbolsEmailLimitErrorMessage)
              .email(I18n.t(i18nInvalidEmailInput))
              .required(I18n.t(i18nRequiredKey, { field: emailI18n })),

    password: Yup.string()
                 .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
                 .min(6, I18n.t(i18nMinNumberKey, { field: passwordI18n }))
                 .required(I18n.t(i18nRequiredKey, { field: passwordI18n })),
    remember_me: Yup.boolean(),
  }),

  handleSubmit: (values, bag) => {
    bag.props.handleSubmit(values);
  },

  displayName: 'LoginForm',
})(LoginFormWrapper);

export default LoginForm;
