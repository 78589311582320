import React, { Component } from 'react';

import { apiProvider } from '../../api/core';
import { requestBuilder } from '../../api/httpService';
import { loadTawkTo } from '../TawkTo/TawkTo';
import LoginForm from './LoginForm';

import './index.sass';


class LoginPage extends Component {
  state = {
    loginError: '',
  };

  componentDidMount() {
    loadTawkTo(this.props.siteKey);
  };

  handleLoginSubmit = (values) => {
    const { loginPath, formAuthToken: authenticity_token } = this.props;
    const { email: login, password, remember_me, fifth_element } = values;
    const requestParams = {
      login,
      authenticity_token,
      password,
      remember_me,
      fifth_element,
    };

    apiProvider.post(loginPath, requestBuilder(requestParams), true)
               .then(response => {
                 response.redirect_url
                   ? window.location.href = response.redirect_url
                   : response.error && this.setState({ loginError: response.data.error.message || response.statusText });
               })
  }

  render() {

    const { createAccountLink,
            siteKey,
            siteName,
            signupFormPath,
            aboutUsLink,
            contactEmail,
            contactPhone,
            forgotPasswordLink } = this.props;

    const phoneNumber = siteKey === 'dk' ? '(+45) 39 45 85 57' : contactPhone;


    return (
      <div className="login" id='login'>
        <div className="login__main-content-wrapper">
          <div className="container">
            <div className="login__main-content row">
              <div className="col-md-6 col-lg-5 col-xl-6">
                <div className="login__top-content">
                  <h1>{ I18n.t('views.sessions.new.headline', { site_name: siteName }) }</h1>
                  <p className="login__header-paragraph">
                    { I18n.t('views.sessions.new.explain') }
                  </p>
                </div>
                <h2>{ I18n.t('views.sessions.login_box.still_not_a_user') }</h2>
                <div dangerouslySetInnerHTML={{__html: I18n.t('views.sessions.login_box.sign_up_teaser_new', {site_name: siteName, create_account_link: signupFormPath})}} />
                <h2>{ I18n.t('views.sessions.login_box.tenant_header') }</h2>
                <div className="login__subheader-last-paragraph" dangerouslySetInnerHTML={{__html: I18n.t('views.sessions.login_box.tenant_paragraph')}} />
              </div>
              <div className="col-md-6 col-xl-5 offset-lg-1">
                <div className="login__top-content login__top-content--mobile">
                  <h1>{ I18n.t('views.sessions.new.headline', { site_name: siteName }) }</h1>
                  <p className="login__header-paragraph">
                    { I18n.t('views.sessions.new.explain') }
                  </p>
                </div>

                <LoginForm createAccountLink={createAccountLink}
                           forgotPasswordLink={forgotPasswordLink}
                           loginError={this.state.loginError}
                           handleSubmit={this.handleLoginSubmit} />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="login__media-blocks row">
            <div className="login__media-block col-md-12 col-lg-4">
              <div className="login__media-img-wrapper  login__media-img-wrapper--first">
                <img src={require('../../src/images/provider_login/virtual_tour.png')} alt="Virtual tour" />
              </div>
              <div className="login__media-block-info">
                <h2>{ I18n.t('views.sessions.new.virtual_tour_info.headline') }</h2>
                <p>{ I18n.t('views.sessions.new.virtual_tour_info.description') }</p>
                <p>{ I18n.t('views.sessions.new.virtual_tour_info.cta') }</p>
              </div>
            </div>
            <div className="login__media-block col-md-12 col-lg-4">
              <div className="login__media-img-wrapper">
                <img src={require('../../src/images/provider_login/all_team.jpg')} alt="Our Team" />
              </div>
              <div className="login__media-block-info">
                <h2>{ I18n.t('views.sessions.new.we_are_ready_to_help.headline') }</h2>
                <p>{ I18n.t('views.sessions.new.we_are_ready_to_help.description', { site_name: siteName }) }</p>
                <a href={aboutUsLink} target="_blank">{ I18n.t('views.sessions.new.we_are_ready_to_help.cta') }</a>
              </div>
            </div>
            <div className="login__media-block col-md-12 col-lg-4">
              <div className="login__media-img-wrapper">
                <img src={require('../../src/images/provider_login/jakob.jpg')} alt="Jakob" />
              </div>
              <div className="login__media-block-info">
                <h2>{ I18n.t('views.sessions.new.got_an_idea.headline') }</h2>
                <p>{ I18n.t('views.sessions.new.got_an_idea.description_jakob', { site_name: siteName }) }</p>
                <a href={`mailto: ${contactEmail}`}>{ I18n.t('views.sessions.new.got_an_idea.cta_jakob', { email: contactEmail }) }</a>
              </div>
            </div>
          </div>

          <div className="login__contacts row">
            <div className="col-md-12">
              <div className="login__contacts-wrapper">
                <div className="login__content-wrapper">
                  <h2 className="login__contacts-header">{ I18n.t('views.sessions.new.get_personalized_help.headline') }</h2>
                  <p className="login__contacts-paragraph">{ I18n.t('views.sessions.new.get_personalized_help.description') }</p>

                  { phoneNumber &&
                    <a href={`tel: ${phoneNumber}`} className="login__contacts-info-link">
                      <img src={require('../../src/images/icons/phone_icon.svg')} alt="Phone" />
                      <span>{ phoneNumber }</span>
                    </a>
                  }

                  <a href={`mailto: ${contactEmail}`} className="login__contacts-info-link">
                    <img src={require('../../src/images/icons/mail.svg')} alt="Email" />
                    <span>{ contactEmail }</span>
                  </a>

                  <h3>{ I18n.t('simple_form.labels.parking_lot.opening_hours') }</h3>
                  <div className="login__working-hours">
                    <div>
                      <p>{ I18n.t('views.sessions.new.opening_hours_weekdays') }</p>
                      <p>{ I18n.t('views.sessions.new.opening_hours_weekend') }</p>
                    </div>
                    <div>
                      <p>{ siteKey === 'dk' ? '09:00-16:30' : '09:00-17:00' }</p>
                      <p>{ I18n.t('generic.closed') }</p>
                    </div>
                  </div>
                </div>
                <div className="login__contacts-img">
                  <img src={require('../../src/images/provider_login/banner_img.svg')} alt="Poster" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginPage;
