//import 'core-js/es6/string';
import RootComponent from "../../components/SharedComponents/RootComponent";
import LoginPage from "../../components/LoginPage";
import * as Sentry from '@sentry/browser';

if (process.env.RAILS_ENV == 'production') {
    Sentry.init({dsn: "https://a15e533883ac4e09a17ba54ccba0ebdc@o35589.ingest.sentry.io/5260847"});
}

class LoginRoot extends RootComponent {

    childComponentsMap = [
        {
            dom_container_id: 'login',
            component: LoginPage,
            props: this.props.login,
        }
    ]
}

export default LoginRoot;
